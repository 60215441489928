<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>
      <ubicacionesForm
        :ubicacion="ubicacionData"
        ubicacionSubmit="Crear Ubicación"
        @processUbicacion="agregarUbicacion"
      />
    </b-card>
  </b-overlay>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ubicacionesForm = () => import('@/layouts/components/Ubicaciones/UbicacionesForm.vue')

export default {
  components: {
    BCard,
    BOverlay,
    ubicacionesForm,
  },
  data() {
    return {
      spinner: false,
      ubicacionData: {
        nombre: '',
        estado: null,
      },
    }
  },
  methods: {
    ...mapActions({ createUbicacion: 'ubicaciones/addUbicaciones' }),
    agregarUbicacion(ubicacion) {
      this.spinner = true
      this.createUbicacion(ubicacion).then(() => {
        const errorUbicaciones = store.state.ubicaciones
        const errorMessage = errorUbicaciones.errorMessage.errors
        if (!errorUbicaciones.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ubicación creada con éxito 👍',
              icon: 'CheckIcon',
              text: `La ubicación "${ubicacion.nombre}" fue creada con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'ubicaciones',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
